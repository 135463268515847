@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
html, body{width: 100%; line-height: normal; -webkit-font-smoothing: antialiased; font-family: 'Inter', sans-serif !important;font-weight: 400; letter-spacing: 0.4px; color: #1a1a1a;position: relative;overflow-x: hidden;scroll-behavior: smooth;background-color: #262626;}
* {
	box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {font-weight: normal;line-height: normal;}
dl,
dt,
dd,
ol,
ul,
li {margin: 0;padding: 0;list-style-type: none;}
a:active,
select,
select:focus,
a:focus,
a:hover,
input:focus,
button:focus,
textarea:focus {outline: none !important;text-decoration: none;-webkit-transition: all 0.5s ease 0s;-moz-transition: all 0.5s ease 0s;transition: all 0.5s ease 0s;box-shadow: none !important;}
img {max-width: 100%;height: auto;}
.form-select:focus {border-color: transparent;outline: 0;box-shadow: none;}
a {text-decoration: none; -webkit-transition: all 0.5s ease 0s;-moz-transition: all 0.5s ease 0s;transition: all 0.5s ease 0s;}
a:hover{text-decoration: none !important;color: #db261f;}
label {font-weight: normal;}

::-webkit-input-placeholder {color: #fff;}
::-moz-placeholder {color: #fff;}
:-ms-input-placeholder {color: #fff;}
:-moz-placeholder { color: #fff;}

.screen-wrapper{background-color: #262626;}
section{background-color: #262626; padding: 50px 15px;}
{/* ************************************************
Login
************************************************ */}

.login-screen{ height: 100vh;}
.login-screen .logo{height: 90px;}
.login-screen h2{color: #fff;font-size: 34px; margin: 30px 0;}
.login-screen .form-control{background-color: rgba(255,255,255,0.3) !important;border: 0;height: 55px;color: #fff;border-radius: 0;}
.login-screen .btn-red{height: 55px; line-height: 55px; display: block; background-color: #db261f; color: #fff; text-align: center; font-size: 18px;font-weight: 500;width: 100%;border: 0;}
.login-screen .form-control::-webkit-input-placeholder {color: #fff;}
.login-screen .form-control::-moz-placeholder {color: #fff;}
.login-screen .form-control:-ms-input-placeholder {color: #fff;}
.login-screen .form-control:-moz-placeholder { color: #fff;}

{/* ************************************************
Dashboard
************************************************ */}
.dashboard .logo{height: 70px;}
.dashboard .btn-red{height: 55px; line-height: 55px; display: block; background-color: #db261f; color: #fff; text-align: center; font-size: 18px;font-weight: 500;width: 100%;border: 0;border-radius: 0;padding: 0 20px;}
.dashboard h5{color: #fff;margin-top: 30px;font-size: 20px;margin-bottom: 15px;}
.dashboard small{color: #fff;font-weight: 500;font-size: 17px;}
.dashboard h6{color: #939393;margin-top: 5px;font-size: 13px;margin-bottom: 50px;text-transform: uppercase;}
.dashboard .blue{background-color: #1266E8 !important;}
.dashboard .search{background-color: #ffd0ce !important; color: #db261f;}
.dashboard .btn-bordred{border: 2px solid #db261f;background-color: transparent; line-height: 50px;}
.dashboard .logout{height: 60px;}

{/* ************************************************
New Leads.
************************************************ */}
.leads{padding: 30px 0px;}
.leads .page-title{color: #fff;font-size: 22px;font-weight: 500;margin-bottom: 0;}
.leads .btn-back{color: #fff; height: 45px; line-height: 45px; padding: 0; margin-right: 15px; border-radius: 10px; width: 45px;background-color: #db261f;font-size: 25px;}
.leads .leads-list .list-group-item{padding: 15px;border-radius: 10px;border: 0;text-align: left; margin-bottom: 10px;}
.leads .leads-list .list-group-item h4{font-size: 17px;font-weight: 500;}
.leads .leads-list .list-group-item p{margin-bottom: 10px;font-weight: 500;font-size: 14px;display: flex; float: left;margin-right: 20px;}
.leads .leads-list .list-group-item p i{font-size: 18px; margin-right: 10px;}
.leads .leads-list .list-group-item p small{font-size: 13px; color: #6a6a6a;display: block; margin-bottom: 3px;font-weight: 400;}
.leads .leads-list .list-group-item ul li{list-style-type: disc;list-style-position: inside;float: left; padding-right: 5px;font-size: 14px;}
.leads .leads-list .list-group-item .btn-details{color: #0821FB;font-size: 13px;text-transform: uppercase;display: inline-block;}

.leads .form-label{color: #fff;}

{/* ************************************************
New Leads Details
************************************************ */}
.leads-details .date_time{font-size: 12px;color: #6a6a6a;margin-bottom: 5px;display: block;}
.leads-details .leads-list .list-group-item h4{font-size: 16px;font-weight: 500;margin-bottom: 20px;}
.leads-details .leads-list .list-group-item p{margin-bottom: 20px;}
.leads-details .leads-list .list-group-item .btn-red{height: 55px; line-height: 55px; display: block; background-color: #db261f; color: #fff; text-align: center; font-size: 17px;font-weight: 500;width: 100%;border: 0;border-radius: 0;padding: 0 20px;}
.leads-details .leads-list .list-group-item .blue{background-color: #1266E8 !important;}
.leads-details .leads-list .list-group-item .green{background-color: #1b9937;}
.leads-details .leads-list .list-group-item .btn-add{display: block;height: 50px;width: 100%; margin-top: 20px;border-radius: 0;}

.leads .list-group .list-group-item .form-check{margin-bottom: 15px;}
.leads .list-group .list-group-item .form-check-input:checked{background-color: #db261f;border-color: #db261f;}
.leads .btn-red{height: 55px; line-height: 55px; display: block; background-color: #db261f; color: #fff; text-align: center; font-size: 18px;font-weight: 500;width: 100%;border: 0;border-radius: 0;padding: 0 20px;}

.capture-activity h4{text-align: left;margin-bottom: 20px;}
.blue{background-color: #1266E8 !important;}

.leads .form-control{background-color: #ffffff1c;border: 0;height: 55px;color: #fff;border-radius: 0;}
.leads .form-control option{color: #000;}

.react-datepicker-wrapper{width: 100%;}

.leads .form-control::-webkit-input-placeholder {color: #fff;}
.leads .form-control::-moz-placeholder {color: #fff;}
.leads .form-control:-ms-input-placeholder {color: #fff;}
.leads .form-control:-moz-placeholder { color: #fff;}


{/* ************************************************
Diary
************************************************ */}
.diary{padding: 30px 0px;}
.diary .page-title{color: #fff;font-size: 22px;font-weight: 500;margin-bottom: 0;}
.diary .btn-back{color: #fff; height: 45px; line-height: 45px; padding: 0; margin-right: 15px; border-radius: 10px; width: 45px;background-color: #db261f;font-size: 25px;}
.diary h6{color: #fff;font-size: 20px; margin-bottom: 15px;}
.diary .table th{font-weight: 500;font-size: 14px;vertical-align: middle;background-color: #1266E8; color: #fff;}
.diary .table td{font-size: 13px;font-weight: 300;vertical-align: middle;}
.diary .table td .btn{font-size: 20px;padding: 0px; }
.diary .table th, .diary .table td{padding: 5px;}
.diary .table-responsive{height: 200px;}


.notes .form-label {color: #000;}
.notes .form-control{border: var(--bs-border-width) solid var(--bs-border-color);height: auto;color: #000;margin-bottom: 10px;}